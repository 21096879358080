export const LocaleConfig = {
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene.",
    "Feb.",
    "Mer",
    "Abr",
    "May",
    "Jun",
    "Jul.",
    "Ago",
    "Sep.",
    "Oct.",
    "Nov",
    "Dic",
  ],
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  today: "Hoy",
}

export const getDateFormat = (date = null, type = "editorial") => {
  var data = null
  if (date) {
    date = new Date(date)
  } else {
    date = new Date()
  }
  switch (type) {
    case "editorial":
      data = getEditorialFormat(
        date.getUTCDate(),
        date.getUTCMonth() + 1,
        date.getFullYear()
      )
      break

    default:
      break
  }
  return data
}

export const getEditorialFormat = (day, month, year = null) => {
  var data = `${day} de ${LocaleConfig.monthNames[month - 1].toLowerCase()}`
  if (year) {
    data = data.concat(` del ${year}`)
  }
  return data
}

export const getDateRegularES = (dateObj, loc = "es-ES") => {
  const dateLocOpts = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  return dateObj.toLocaleDateString(loc, dateLocOpts)
}
